import { Controller } from '@hotwired/stimulus'
import hljs from 'highlight.js/lib/core'
import xml from 'highlight.js/lib/languages/xml'
import javascript from 'highlight.js/lib/languages/javascript'
import json from 'highlight.js/lib/languages/json'
import python from 'highlight.js/lib/languages/python'
import ruby from 'highlight.js/lib/languages/ruby'

export default class extends Controller {
  connect() {
    hljs.registerLanguage('xml', xml)
    hljs.registerLanguage('javascript', javascript)
    hljs.registerLanguage('json', json)
    hljs.registerLanguage('python', python)
    hljs.registerLanguage('ruby', ruby)
    hljs.highlightAll()
  }
}
