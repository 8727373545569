import PricingController from '../pricing_controller.js'

export default class extends PricingController {
  static targets = [
    'blacklistMonitorFrequency', 'blacklistMonitors', 'inboxReports',
    'monthlyPrice', 'savings', 'toggle', 'yearlyPrice'
  ]

  connect() {
    this.handlePriceRequest()
  }

  togglePaymentFrequency() {
    const { checked } = this.toggleTarget
    this.plan = checked ? 'monthly' : 'pay-as-you-go'
    this.element.dataset.defaultPriceVisible = !checked
  }

  updatePrice() {
    this.handlePriceRequest()
  }

  async handlePriceRequest() {
    const data = await this.requestPrice('deliverability')
    const yearData = data.terms.find(term => term.interval === 'year')

    this.monthlyPriceTarget.textContent = data.terms.find(
      term => term.interval === 'month'
    ).amount.formatted

    this.yearlyPriceTarget.textContent = yearData.amount.formatted
    this.savingsTarget.textContent = yearData.savings_amount.formatted
    this.inboxReportsTarget.textContent = data.terms[0].limits.inbox_reports

    this.blacklistMonitorsTarget.textContent =
      data.terms[0].limits.blacklist_monitors

    this.blacklistMonitorFrequencyTarget.textContent =
      `${data.terms[0].limits.blacklist_monitor_frequency}-Hour`

    this.updateCurrency(data.currency.iso_code)
  }
}
