import { Controller } from '@hotwired/stimulus'
import Banner from '../banner.js'

export default class extends Controller {
  static targets = ['bannerTemplate']

  showRedirectBanner(event) {
    const { code } = event.detail
    this.bannerTemplateTargets.forEach(bannerTemplate => {
      if (bannerTemplate.dataset.id === code && Banner.shouldShow(code)) {
        this.element.prepend(bannerTemplate.content.cloneNode(true))
        Banner.clearDismissal(code)
      }
    })
  }
}
