import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['trigger', 'popout', 'closeButton']
  static values = {
    active: { default: false, type: Boolean },
    fixed: { default: false, type: Boolean },
    matchTriggerWidth: { default: false, type: Boolean }
  }

  connect() {
    this.initialPositionSet = false
    this.triggerWidth = this.hasTriggerTarget
      ? this.triggerTarget.offsetWidth
      : null
  }

  close() {
    this.activeValue = false
  }

  handleClick(event) {
    if (!this.element.contains(event?.target)) this.close()
  }

  toggle() {
    if (this.hasTriggerTarget && this.matchTriggerWidthValue) {
      this.popoutTarget.style.width = `${this.triggerWidth}px`
    }

    this.activeValue = !this.activeValue
  }
}
