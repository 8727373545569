import { Controller } from '@hotwired/stimulus'
import anime from 'animejs/lib/anime.es.js'

export default class extends Controller {
  static targets = ['bubble']

  connect() {
    this.animateBubbles()
  }

  animateBubbles() {
    setTimeout(() => {
      anime({
        delay: anime.stagger(100),
        duration: 1600,
        easing: 'easeOutElastic(1.5, .4)',
        opacity: [0, 1],
        scale: [0.5, 1],
        targets: this.bubbleTargets
      })
    }, 500)
  }
}
