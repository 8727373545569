import { Controller } from '@hotwired/stimulus'
import { apiURL } from '../utilities'

export default class extends Controller {
  static targets = ['freeCredits']

  async connect() {
    const urlParams = new URLSearchParams(location.search)
    const systemCodeParam =
      this.#fromPartnerPath() ||
      urlParams.get('sc') || // used for system codes
      urlParams.get('redirect_source') || // used for acquisition redirects
      urlParams.get('via') || // used for rewardful urls
      urlParams.get('ic') || // backwards compatibility
      urlParams.get('invite_code') // backwards compatibility

    if (systemCodeParam) {
      this.systemCode = await this.#loadSystemCode(systemCodeParam)
    } else {
      const systemCodeJSON = localStorage.getItem('system_code')
      if (systemCodeJSON) this.systemCode = JSON.parse(systemCodeJSON)
    }
    if (this.systemCode === undefined) return

    // Hacky fix to dispatch this after the top-bar controller connects until
    // we find a better solution
    setTimeout(() => this.dispatch('ready', { detail: this.systemCode }), 10)
    this.#updateFreeCreditsText()
    this.#setSignupSourceCodeCookie()
  }

  async #loadSystemCode(code) {
    const systemCodeResponse = await fetch(
      `${apiURL()}/site/system_codes/${code}`
    )
    if (systemCodeResponse.ok) {
      const json = await systemCodeResponse.json()
      localStorage.setItem('system_code', JSON.stringify(json))
      return json
    }
  }

  #updateFreeCreditsText() {
    this.freeCreditsTargets.forEach(el => {
      el.textContent = this.systemCode.free_credits.toLocaleString()
    })
  }

  // set cookie for registration to read
  #setSignupSourceCodeCookie() {
    const domain = location.hostname.includes('emailable.com')
      ? 'emailable.com'
      : location.hostname
    document.cookie = `signup_source_code=${this.systemCode.code}; ` +
                      `domain=${domain}; path=/; secure`
  }

  // when a user visits a partner page, the URL will include the system code in
  // the path (ex: `/partners/brevo` should be brevo)
  #fromPartnerPath() {
    if (!location.pathname.startsWith('/partners/')) return
    const partnerMatch = location.pathname.match(/\/partners\/(.+)/)
    if (partnerMatch) return partnerMatch[1]
  }
}
