export const getClosestElement = (startEl, targetEl) => {
  let element = false

  const isElement = function (o) {
    if (typeof HTMLElement === 'object') {
      return o instanceof HTMLElement
    } else {
      return (
        o &&
        typeof o === 'object' &&
        o !== null &&
        o.nodeType === 1 &&
        typeof o.nodeName === 'string'
      )
    }
  }

  const traverse = function (parent) {
    if (parent.isEqualNode(targetEl)) {
      return parent
    }
    if (parent.isEqualNode(document.body)) {
      return document.body
    }

    return traverse(parent.parentNode)
  }

  if (isElement(targetEl)) {
    element = startEl.isEqualNode(targetEl)
      ? startEl
      : traverse(startEl.parentNode)
  } else {
    if (Array.isArray(targetEl)) {
      let matchedEl = null

      targetEl.forEach(function (item) {
        if (item.contains(startEl)) {
          matchedEl = item
          return
        } else if (item.isEqualNode(startEl)) {
          matchedEl = startEl
          return
        }
      })

      return matchedEl
    } else if (typeof targetEl === 'object') {
      for (const key in targetEl) {
        const node = targetEl[key]
        if (node.contains(startEl)) {
          element = node
        }
      }
    }
  }

  return element
}

export const closestAncestor = function (startEl, targetEl) {
  if (startEl === null || targetEl === null) return null

  let element = null

  const isElement = function (o) {
    if (typeof HTMLElement === 'object') {
      return o instanceof HTMLElement
    } else if (
      targetEl &&
      typeof (targetEl === 'object') &&
      targetEl !== null &&
      targetEl.nodeType === 1 &&
      typeof (targetEl.nodeName === 'string')
    ) {
      return true
    }
  }

  const traverse = function (parent) {
    if (parent === null) return null
    if (parent.isEqualNode(targetEl)) return parent
    if (parent.isEqualNode(document.body)) return null

    return traverse(parent.parentNode)
  }

  if (isElement(targetEl)) {
    element = startEl.isEqualNode(targetEl)
      ? startEl
      : traverse(startEl.parentNode)
  } else {
    if (Array.isArray(targetEl)) {
      targetEl.forEach(function (item) {
        if (item.contains(startEl)) return (element = item)
      })
    } else if (typeof targetEl === 'object') {
      for (const key in targetEl) {
        const node = targetEl[key]
        if (node.contains(startEl)) {
          element = node
        }
      }
    }
  }

  return element
}

export const getURLParam = (parameter, url) => {
  if (!url) url = window.location.search
  const urlParams = new URLSearchParams(url)
  return urlParams.get(parameter)
}

export const isElementVisible = (elm, threshold, mode) => {
  threshold = threshold || 0
  mode = mode || 'visible'

  const rect = elm.getBoundingClientRect()
  const viewHeight = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight
  )
  const above = rect.bottom - threshold < 0
  const below = rect.top - viewHeight + threshold >= 0

  if (mode === 'above') {
    return above
  } else if (mode === 'below') {
    return below
  } else {
    return !above && !below
  }
}

export const parameterize = (str) => {
  return str
    .trim()
    .toLowerCase()
    .replace(/\./g, '-')
    .replace(/[^a-zA-Z0-9 -]/, '')
    .replace(/\s/g, '-')
}

export const randomInRange = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export const shuffle = (a) => {
  let j = undefined
  let x = undefined
  let i = undefined
  i = a.length - 1
  while (i > 0) {
    j = Math.floor(Math.random() * (i + 1))
    x = a[i]
    a[i] = a[j]
    a[j] = x
    i--
  }
  return a
}

export const apiURL = () => {
  return document.body.getAttribute('data-api-base-url')
}

export const setRAFInterval = (callback, interval) => {
  let lastTime = performance.now()

  function loop(currentTime) {
    const delta = currentTime - lastTime

    if (delta >= interval) {
      callback()
      lastTime = currentTime
    }

    requestAnimationFrame(loop)
  }

  requestAnimationFrame(loop)
}

export default {
  getClosestElement,
  getURLParam,
  isElementVisible,
  parameterize,
  randomInRange,
  shuffle,
  apiURL
}
