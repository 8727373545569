import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['bg']
  static values = { items: Array, amount: Number }

  connect() {
    const items = this.itemsValue.sort(() => 0.5 - Math.random())
      .slice(0, this.amountValue)

    this.bgTargets.forEach((el, i) => {
      el.style.backgroundImage = `url(${items[i].path})`
    })
  }
}
