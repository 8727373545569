import { Controller } from '@hotwired/stimulus'
import anime from 'animejs'

export default class extends Controller {
  static targets = ['logo']

  connect() {
    this.currentIndex = 0
    this.slideDuration = 800
    this.#randomizeSlides()
    this.#showFirstLogo()
    this.#initAnimation(this.#slideLogos.bind(this), 2000)
  }

  #getNextIndex() {
    return this.currentIndex + 1 > this.logoTargets.length - 1
      ? 0
      : this.currentIndex + 1
  }

  #initAnimation(callback, interval) {
    let lastTime = performance.now()

    function loop(currentTime) {
      const delta = currentTime - lastTime

      if (delta >= interval) {
        callback()
        lastTime = currentTime
      }

      requestAnimationFrame(loop)
    }

    requestAnimationFrame(loop)
  }

  #showFirstLogo() {
    anime({
      targets: this.logoTargets[this.currentIndex],
      duration: this.slideDuration,
      translateX: ['100%', 0],
      easing: 'easeInOutQuart'
    })
  }

  #slideLogos() {
    const timeline = anime.timeline()
    const nextIndex = this.#getNextIndex()

    timeline.add({ // Slide current logo left out of view
      targets: this.logoTargets[this.currentIndex],
      duration: this.slideDuration,
      translateX: [0, '-100%'],
      easing: 'easeInOutQuart',
      complete: () =>
        this.logoTargets[this.currentIndex].style.translateX = '100%'
    })
      .add({ // Slide next logo left into view at the same time
        targets: this.logoTargets[nextIndex],
        duration: this.slideDuration,
        translateX: ['100%', 0],
        easing: 'easeInOutQuart',
        complete: () => this.currentIndex = nextIndex
      }, `-=${this.slideDuration}`)
  }

  #randomizeSlides() {
    for (let i = this.element.children.length; i >= 0; i--) {
      this.element.appendChild(this.element.children[Math.random() * i | 0])
    }
  }
}
