import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['progress', 'article']

  update() {
    const viewportH = window.innerHeight
    const scrollTop = document.documentElement.scrollTop
    const articleH = this.articleTarget.getBoundingClientRect().height
    const articleY = this.articleTarget.getBoundingClientRect().top
      + window.scrollY
    const articleEnd = articleH + articleY
    const progressEnd = articleEnd - viewportH
    const progress = (scrollTop / progressEnd) * 100
    const barProgress = progress > 100 ? 100 : progress
    this.progressTarget.value = barProgress
  }
}
