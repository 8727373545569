import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['accordionItem']

  toggleItem(event) {
    const expanded = event.currentTarget.ariaExpanded === 'true'
    event.currentTarget.ariaExpanded = !expanded
    event.target.closest('li').dataset.active = !expanded
  }
}
