import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']

  toggle() {
    this.inputTarget.checked = !this.inputTarget.checked
    this.inputTarget.dispatchEvent(new Event('input'))
  }
}
