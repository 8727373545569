import { Controller } from '@hotwired/stimulus'
import { apiURL, getURLParam } from '../utilities.js'

export default class extends Controller {
  static targets = [
    'currency', 'currencyCode', 'errorMessage', 'flagTemplate', 'loadingCtr',
    'loadedValue'
  ]

  connect() {
    // this will be set to `true` after the first API call to prevent
    // unnecessary DOM manipulations
    this.currencyShown = false
  }

  // used for identifying invalid key press when focused on a select box. we
  // only want to select the box on enter keypress.
  selectBoxInvalidKeyPress(event) {
    return (
      event.currentTarget.tagName === 'BOX' &&
      event.type === 'keypress' &&
      event.code !== 'Enter'
    )
  }

  async requestPrice(endpoint, credits = null) {
    this.errorMessageTarget.classList.add('hidden')
    this.#setLoading()

    // build URL and get country specific pricing if ?country param is present
    const priceURL = new URL(`${apiURL()}/site/prices/${endpoint}`)

    // add country override param to API request
    const countryParam = getURLParam('country')
    if (countryParam) priceURL.searchParams.append('country', countryParam)

    if (credits) priceURL.searchParams.append('quantity', credits)

    const response = await fetch(priceURL)
    this.#clearLoading()

    if (response.ok) {
      return await response.json()
    } else {
      this.errorMessageTarget.classList.remove('hidden')
    }
  }

  getUserInputValue(event) {
    if (['INPUT', 'SELECT'].includes(event.target.tagName)) {
      return this.#sanitizeInput(event.target.value)
    } else {
      return event.params.credits // select box sends a `credits` param
    }
  }

  updateCurrency(isoCode) {
    // only show currency/flag for non USD currencies and do not continually
    // re-render if it is already shown
    if (isoCode === 'USD' || this.currencyShown) return

    const currentCountry = getURLParam('country')
    const flagTemplate = document.querySelector(
      `template[data-country=${currentCountry}]`
    )
    this.currencyTarget.prepend(flagTemplate.content.cloneNode(true))
    this.currencyTarget.querySelector('currency-code').textContent = isoCode
    this.currencyTarget.classList.remove('hidden')
    this.currencyShown = true
  }

  #setLoading() {
    // show a loading spinner if the request takes longer than 500ms
    this.loadingTimeout = setTimeout(() => {
      this.loadingCtrTargets.forEach(el => el.dataset.loading = true)
      this.loadedValueTargets.forEach(el => el.textContent = '—')
    }, 500)
  }

  #clearLoading() {
    clearTimeout(this.loadingTimeout)
    this.loadingCtrTargets.forEach(el => el.dataset.loading = false)
  }

  #sanitizeInput(credits) {
    // strip out any non-numeric characters and return credits as an integer
    credits = credits.replace(/[.,]/g, '').replace(/^0+(?!\.|$)/, '')
    credits = parseInt(credits)
    if (isNaN(credits)) credits = 0
    return credits
  }
}
