import { Controller } from '@hotwired/stimulus'
import Banner from '../banner.js'

export default class extends Controller {
  static values = { id: String }

  close() {
    this.element.remove()
    localStorage.setItem(this.localStorageKey, true)
    Banner.dismiss(this.idValue)
  }
}
