import { Controller } from '@hotwired/stimulus'
import anime from 'animejs/lib/anime.es.js'

export default class extends Controller {
  static targets = ['code']

  writeCode() {
    const spans = []
    this.codeTarget.querySelectorAll('span').forEach(span => spans.push(span))
    const tl = anime.timeline()

    tl.add({
      targets: this.element.closest('.transform-wrapper'),
      opacity: [0, 1],
      rotate: ['2deg', '2deg'],
      rotateY: ['-20deg', '-20deg'],
      rotateX: ['4deg', '4deg'],
      translateZ: [-150, 0],
      duration: 1400,
      easing: 'cubicBezier(.5,0,0,1)'
    }).add({
      targets: spans,
      opacity: {
        duration: 300,
        value: [0, 1]
      },
      translateY: {
        duration: 500,
        value: ['1em', 0]
      },
      delay: anime.stagger(10),
      easing: 'cubicBezier(.05,.5,.2,1)'
    }, '-=200')
  }
}
