import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { websiteId: String }

  initialize() {
    window.$crisp = []
    window.CRISP_WEBSITE_ID = this.websiteIdValue
    const script = document.createElement('script')
    script.src = 'https://client.crisp.chat/l.js'
    script.async = 1
    document.body.insertAdjacentElement('beforeend', script)
  }

  open() {
    window.$crisp.push(['do', 'chat:open'])
  }
}
