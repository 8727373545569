import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { overlayShown: { default: false, type: Boolean }}
  static targets = ['mobileDropdown']

  hideOverlay() {
    this.overlayShownValue = false
  }

  toggleOverlay() {
    this.overlayShownValue =
      this.mobileDropdownTarget.getAttribute('data-dropdown-active-value')
  }
}
