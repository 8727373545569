import { getURLParam } from './utilities.js'

export default class Banner {

  // show if it wasn't previously closed or we're coming from a redirect.
  static shouldShow(id) {
    const closed = localStorage.getItem(`closed_banner_${id}`)
    const fromRedirect = getURLParam('redirect_source')
    return !closed || fromRedirect
  }

  static dismiss(id) {
    localStorage.setItem(`closed_banner_${id}`, true)
  }

  static clearDismissal(id) {
    localStorage.removeItem(`closed_banner_${id}`)
  }

}
