import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  connect() {
    // this removes any query parameters left from certain redirects
    window.history.replaceState({}, document.title, window.location.pathname)
  }

  setRewardfulCode(event) {
    window.rewardful('source', event.detail.code)
  }
}
