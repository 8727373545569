import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['hexCode', 'copyIcon', 'copiedText']

  copyColor(event) {
    const target = event.currentTarget
    target.setAttribute('data-copied', true)

    // Reset the timer if the swatch has been copied multiple times in quick succession
    if (target?.data?.copiedTimer) {
      clearTimeout(target.data.copiedTimer)
    }

    // Set a timer to hide the "Copied!" text after 2 seconds
    const copiedTimer = setTimeout(() => {
      target.setAttribute('data-copied', false)
    }, 2000)

    // Attach the timer to the swatch
    if (!target?.data) {
      target.data = { copiedTimer }
    }

    const hexCode =
      event
        .currentTarget
        .querySelector('[data-swatch-target="hexCode"]')
        .textContent

    navigator.clipboard.writeText(hexCode)
  }
}
