import { Controller } from '@hotwired/stimulus'
import anime from 'animejs/lib/anime.es.js'

export default class extends Controller {
  static targets = [
    'acceptAll', 'button', 'didYouMean', 'disposable', 'domain',
    'examplePrompt', 'free', 'fullName', 'gender', 'input', 'mailboxFull',
    'meterMarker', 'meterScore', 'mxRecord', 'noReply', 'rateLimitPrompt',
    'reason', 'results', 'resultsForText', 'resultsInner', 'resultItemText',
    'role', 'smtpProvider', 'state', 'stateText', 'tag', 'user'
  ]

  static values = {
    devMode: { type: Boolean, default: false },
    resultsExpanded: { type: Boolean, default: false },
    translations: Object
  }

  connect() {
    this.response = null
    this.tippyOptions = {
      allowHTML: true,
      animation: 'shift-toward',
      duration: 250,
      popperOptions: {
        modifiers: [{
          name: 'flip',
          options: { fallbackPlacements: ['bottom', 'top'] }
        }]
      }
    }
  }

  clearResults() {
    this.stateTarget.setAttribute('data-state', 'unknown')
    this.resultItemTextTargets.forEach(el => el.textContent = '\u2014')
  }

  collapseResults() {
    const resultsCardTimeline = anime.timeline()

    resultsCardTimeline
      .add({
        begin: () => this.resultsTarget.classList.remove('!max-h-none'),
        targets: this.element,
        duration: 600,
        maxWidth: 640,
        easing: 'cubicBezier(.2,0,.07,1)'
      })
      .add({
        targets: this.resultsTarget,
        duration: 600,
        maxHeight: 0,
        easing: 'cubicBezier(.25,0,.35,1)'
      }, '-=600')
  }

  expandResults() {
    if (this.resultsExpandedValue) return

    const resultsCardTimeline = anime.timeline()

    resultsCardTimeline
      .add({
        targets: this.element,
        duration: 600,
        maxWidth: 1280,
        easing: 'cubicBezier(.2,0,.07,1)'
      })
      .add({
        targets: this.resultsTarget,
        duration: 600,
        maxHeight: this.resultsInnerTarget.getBoundingClientRect().height,
        easing: 'cubicBezier(.25,0,.35,1)',
        complete: () => this.resultsTarget.classList.add('!max-h-none')
      }, '-=600')
      .add({
        targets: this.resultsInnerTarget,
        duration: 200,
        opacity: [0, 1],
        complete: () => {
          this.resultsInnerTarget.style.pointerEvents = 'all'
          this.resultsExpandedValue = true
        }
      }, '-=400')
  }

  formatResponseValue(key) {
    const value = this.response[key]

    if (value === true) {
      return this.translationsValue.yes
    } else if (value === false) {
      return this.translationsValue.no
    } else if (value === null || value === undefined || value === '') {
      return '—'
    } else if (this.translationsValue[value]?.length) {
      return this.translationsValue[value]
    } else {
      return value
    }
  }

  populateResults() {
    // General
    this.fullNameTarget.textContent = this.formatResponseValue('full_name')
    this.genderTarget.textContent = this.formatResponseValue('gender')
    this.stateTarget.setAttribute('data-state', this.response.state)
    this.stateTextTarget.textContent = this.formatResponseValue('state')
    this.reasonTarget.textContent = this.formatResponseValue('reason')
    this.domainTarget.textContent = this.formatResponseValue('domain')
    this.userTarget.textContent = this.formatResponseValue('user')
    this.didYouMeanTarget.textContent = this
      .formatResponseValue('did_you_mean')

    // Attributes
    this.acceptAllTarget.textContent = this.formatResponseValue('accept_all')
    this.disposableTarget.textContent = this.formatResponseValue('disposable')
    this.freeTarget.textContent = this.formatResponseValue('free')
    this.roleTarget.textContent = this.formatResponseValue('role')
    this.tagTarget.textContent = this.formatResponseValue('tag')
    this.mailboxFullTarget.textContent =
      this.formatResponseValue('mailbox_full')
    this.noReplyTarget.textContent = this.formatResponseValue('no_reply')

    // Mail Server
    this.smtpProviderTarget.textContent = this
      .formatResponseValue('smtp_provider')
    this.mxRecordTarget.textContent = this.formatResponseValue('mx_record')
  }

  setRateLimitedState() {
    this.inputTarget.disabled = true
    this.buttonTarget.disabled = true
    this.clearResults()
    this.collapseResults()
    this.element.scrollIntoView(true)
    this.rateLimitPromptTarget.classList.remove('hidden')
    this.examplePromptTarget.remove()
  }

  updateMeter() {
    let markerOffset
    const { score, state } = this.response

    if (score >= 80) {
      markerOffset = `${score}%`
    } else if (score > 0 && score < 80) {
      markerOffset = `${Math.round(((score - 2) / 77) * 68 + 11)}%`
    } else if (score === 0) {
      markerOffset = '5%'
    } else if (score === null) {
      markerOffset = '0%'
    }

    // Set the color of the meter marker
    document.documentElement.style.setProperty(
      '--verifier-result-color',
      getComputedStyle(document.documentElement)
        .getPropertyValue(`--color-${state}`)
    )

    this.meterMarkerTarget.style.left = markerOffset
    this.meterScoreTarget.textContent = score === null ? '?' : score
  }

  async verifyEmail(event) {
    if (event) event.preventDefault()
    clearTimeout(this.showLoadingTimeout)

    const email = this.inputTarget.value

    this.showLoadingTimeout = setTimeout(() => {
      this.inputTarget.disabled = true
      this.buttonTarget.disabled = true
      this.buttonTarget.dataset.loading = true
    }, 200)

    const url = new URL('https://emailable.com/test-email-verifier')
    const options = {
      method: 'POST',
      body: JSON.stringify({ email }),
      headers: { 'Content-Type': 'application/json' }
    }

    if (this.devModeValue) {
      url.searchParams.append('test_mode', true)
    } else {
      options.credentials = 'include'
      options.mode = 'same-origin'
    }

    try {
      const response = await fetch(url, options)

      if (response?.status === 200) {
        response.json().then(json => {
          this.response = json
          this.populateResults()
          this.updateMeter()
          this.expandResults()
          this.resultsForTextTarget.textContent = email
        })
      } else {
        this.setRateLimitedState()
      }
    } catch (error) {
      this.setRateLimitedState()
    } finally {
      clearTimeout(this.showLoadingTimeout)
      this.inputTarget.disabled = false
      this.buttonTarget.disabled = false
      this.buttonTarget.dataset.loading = false
    }
  }

  verifyExampleEmail(event) {
    const email = event.target.textContent
    this.inputTarget.value = email
    this.verifyEmail()
  }
}
