import { Controller } from '@hotwired/stimulus'
import { isElementVisible } from '../utilities.js'
import Shuffler from '../shuffler.js'
import anime from 'animejs/lib/anime.es.js'

export default class extends Controller {
  static targets = ['logoCtr', 'logoCtrInner']
  static values = { logos: Array }

  connect() {
    this.preloadLogos()

    const swapCallback = newItem => {
      const swapTimeline = anime.timeline()

      swapTimeline.add({
        targets: newItem.element,
        duration: 800,
        opacity: [1, 0],
        translateY: [0, '25%'],
        easing: 'easeInQuart',
        complete: () => {
          const inner = newItem.element.querySelector('logo')

          inner.style.backgroundImage = `url(${newItem.path})`
          inner.setAttribute('data-company', newItem.id)
        }
      }).add({
        targets: newItem.element,
        duration: 800,
        opacity: [0, 1],
        translateY: ['-25%', 0],
        easing: 'easeOutQuart',
        complete: () => {
          setTimeout(() => {
            this.shuffler.swapNextItem()
          }, 200)
        }
      }, '+=50')
    }

    const queueCallback = (visibleItems, elements) => {
      elements.forEach((element, index) => {
        const company = visibleItems[index]
        const inner = element.querySelector('logo')

        inner.style.backgroundImage = `url(${company.path})`
        inner.setAttribute('data-company', company.id)
      })

      if (this.initialAnimationRan() || !isElementVisible(this.element, 0)) {
        return
      }

      this.showLogos()
    }

    this.shuffler = new Shuffler({
      elements: this.logoCtrTargets,
      data: this.logosValue,
      shuffleData: false,
      queueCallback,
      swapCallback,
      interval: false
    })

    this.shuffler.queueItems()
  }

  initialAnimationRan() {
    return this.element.getAttribute('initial-animation-ran') === 'true'
  }

  preloadLogos() {
    this.logosValue.forEach(company => (new Image()).src = company.path)
  }

  showLogos() {
    if (this.initialAnimationRan() || !isElementVisible(this.element, 0)) {
      return
    }

    this.element.setAttribute('initial-animation-ran', true)

    setTimeout(() => {
      anime({
        targets: this.logoCtrTargets,
        translateY: ['25%', 0],
        opacity: [0, 1],
        easing: 'easeOutElastic(1.5, 0.4)',
        duration: 1600,
        delay: anime.stagger(125),
        complete: () => {
          setTimeout(() => {
            this.shuffler.swapNextItem()
          }, 1000)
        }
      })
    }, 250)
  }
}
