import { Controller } from '@hotwired/stimulus'
import { isElementVisible } from '../utilities.js'
import Shuffler from '../shuffler.js'
import anime from 'animejs/lib/anime.es.js'

export default class extends Controller {
  static targets = ['bubble']
  static values = {
    initialAnimationRan: Boolean,
    integrations: Array
  }

  connect() {
    this.preloadLogos()

    const swapCallback = (newItem) => {
      const swapTimeline = anime.timeline()

      swapTimeline.add({
        targets: newItem.element,
        duration: 800,
        scale: [1, 0.75],
        opacity: [1, 0],
        easing: 'easeInElastic(1.5, 0.5)',
        complete: () => {
          newItem.element.style.backgroundImage = `url(${newItem.path}`
          newItem.element.querySelector('tooltip').textContent =
            newItem.name.replace('&amp;', '&')
        }
      }).add({
        targets: newItem.element,
        duration: 1200,
        scale: [0.75, 1],
        opacity: [0, 1],
        easing: 'easeOutElastic(1.5, 0.5)',
        complete: () => {
          setTimeout(() => {
            this.shuffler.swapNextItem()
          }, 250)
        }
      })
    }

    const queueCallback = (visibleItems, elements) => {
      elements.forEach((bubble, index) => {
        const integration = visibleItems[index]
        bubble.style.backgroundImage = `url(${integration.path})`
        bubble.querySelector('tooltip').textContent =
          integration.name.replace('&amp;', '&')
      })
    }

    this.shuffler = new Shuffler({
      elements: this.bubbleTargets,
      data: this.integrationsValue,
      shuffleData: true,
      queueCallback,
      swapCallback,
      interval: false
    })

    this.shuffler.queueItems()
    this.showBubbles()
  }

  preloadLogos() {
    this.integrationsValue.forEach(integration => {
      (new Image()).src = integration.path
    })
  }

  showBubbles() {
    if (
      this.initialAnimationRanValue || !isElementVisible(this.element, 200)
    ) return

    anime({
      targets: this.bubbleTargets,
      scale: [0.5, 1],
      opacity: [0, 1],
      easing: 'easeOutElastic(1.5, 0.5)',
      duration: 1200,
      delay: anime.stagger(100),
      complete: () => {
        setTimeout(() => {
          this.shuffler.swapNextItem()
        }, 200)
      }
    })

    this.initialAnimationRanValue = true
  }
}
