import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['card', 'trigger']

  setBackground(event) {
    const { company } = event.params
    this.cardTarget.setAttribute('data-company', company)
  }
}
