import { Controller } from '@hotwired/stimulus'
import { setRAFInterval } from '../utilities'

export default class extends Controller {
  static targets = ['item']
  static values = { interval: { type: Number, default: 4000 } }

  connect() {
    this.activeIndex = 0
    setRAFInterval(this.#activateNext.bind(this), this.intervalValue)
  }

  #activateNext() {
    const nextIndex =
      (this.activeIndex + 1) > (this.itemTargets.length - 1)
        ? 0
        : this.activeIndex + 1
    this.itemTargets[this.activeIndex].dataset.active = false
    this.itemTargets[nextIndex].dataset.active = true
    this.activeIndex = nextIndex
  }
}
