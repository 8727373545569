import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['tab', 'tabpanel']

  connect() {
    const { hash } = window.location
    if (hash) this.#activateTab(hash.replace('#', ''))
  }

  toggleTab(event) {
    this.#activateTab(event.currentTarget.dataset.tabId)
  }

  #activateTab(id) {
    this.tabTargets.forEach(tab => {
      const active = tab.dataset.tabId === id
      tab.dataset.active = active
      if (active) {
        tab.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center'
        })
      }
    })

    this.tabpanelTargets.forEach(tabpanel => {
      const active = tabpanel.dataset.tabId === id
      tabpanel.dataset.active = active
      tabpanel.dataset.ariaExpanded = active
    })

    // append tab ID hash to URL and add it to the browser's history
    const newURL = new URL(window.location)
    newURL.hash = id
    window.history.pushState(null, '', newURL)
  }

}
