import { Controller } from '@hotwired/stimulus'
import { delegate } from 'tippy.js'

export default class extends Controller {
  static targets = ['previewBar']

  connect() {
    this.setupTippy()
    if (window.self !== window.top) this.previewBarTarget.remove()
  }

  setupTippy() {
    delegate('body', {
      target: '[data-tippy-content]',
      animation: 'shift-toward',
      duration: 250,
      allowHTML: true,
      popperOptions: {
        modifiers: [
          { name: 'flip', options: { fallbackPlacements: ['bottom', 'top'] } }
        ]
      }
    })
  }
}
