import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['results', 'result', 'input', 'filter', 'noResultsText', 'noResultsQuery']

  filterResults(_event) {
    const query = this.inputTarget.value
    const filterId = this.filterTargets
      .find(tab => tab.dataset.active === 'true')
      .dataset
      .searchFilterId

    this.resultTargets.forEach(result => {
      const filterMatches = result.dataset.searchResultId.includes(filterId)
      const queryMatches =
        result.dataset.searchResultId.includes(query.toLowerCase())

      if (
        (filterId === 'all' && queryMatches)
        || (filterMatches && queryMatches)
      ) {
        result.classList.remove('hidden')
      } else {
        result.classList.add('hidden')
      }
    })

    const matchedResults =
      this.resultTargets.filter(result => !result.classList.contains('hidden'))

    if (!matchedResults.length) {
      this.noResultsTextTarget.classList.remove('hidden')
      this.noResultsQueryTarget.textContent = `"${query}"`
      this.resultsTarget.classList.add('hidden')
    } else {
      this.noResultsTextTarget.classList.add('hidden')
      this.resultsTarget.classList.remove('hidden')
    }
  }
}
