import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static classes = ['scrolled']
  static targets = ['scrollCtr']
  static values = {
    hasStickyHeader: { type: Boolean, default: true }
  }

  updateIsScrolled() {
    this.scrollCtrTarget.scrollTop > 0 && this.hasStickyHeaderValue
      ? this.element.querySelector('thead tr').classList.add(this.scrolledClass)
      : this.element.querySelector('thead tr').classList.remove(this.scrolledClass)
  }
}
