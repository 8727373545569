import { Controller } from '@hotwired/stimulus'
import { randomInRange } from '../utilities.js'
import anime from 'animejs/lib/anime.es.js'

export default class extends Controller {
  static targets = ['mainLayer', 'rippleLayer']

  connect() {
    this.mainDots = this.mainLayerTarget.querySelectorAll('circle')
    this.rippleDots = this.rippleLayerTarget.querySelectorAll('circle')
    this.dotCount = this.mainDots.length
    this.initialPings = 15
    this.maxPings = 80
    this.initialDotR = parseFloat(getComputedStyle(this.mainDots[0]).r)
    this.colorizeInitial()

    setTimeout(() => {
      this.ping()
    }, 1000)

    window.requestAnimationFrame(tick => {
      this.unPing(tick)
    })
  }

  colorizeInitial() {
    while (this.pingedIndexes().length < this.initialPings) {
      const index = this.getAvailableDotIndex()
      this.pingIndex(index)
    }
  }

  getAvailableDotIndex() {
    let randIndex = undefined

    while (
      randIndex === undefined
      || this.mainDots.item(randIndex).classList.contains('pinged')
    ) {
      randIndex = Math.floor(Math.random() * this.dotCount)
    }

    return randIndex
  }

  ping() {
    if (this.pingedIndexes().length >= this.maxPings) { return }

    this.pingIndex(this.getAvailableDotIndex(), true, () => {
      setTimeout(() => {
        this.ping()
      }, randomInRange(100, 3000))
    })
  }

  pingedIndexes() {
    return this.mainLayerTarget.querySelectorAll('circle.pinged')
  }

  pingIndex(index, animate, callback) {
    if (animate == null) { animate = false }
    const mainDot = this.mainDots.item(index)
    const rippleDot = this.rippleDots.item(index)
    mainDot.classList.add('pinged')
    rippleDot.classList.add('pinged')

    if (animate) {
      const tl = anime.timeline()
      tl.add({
        begin: () => {
          if (callback) { callback() }
        },
        targets: rippleDot,
        r: this.initialDotR * 8,
        opacity: [1, 0],
        duration: 2000,
        easing: 'cubicBezier(.2,0,.1,1)'
      }).add({
        targets: mainDot,
        r: this.initialDotR * 1.25,
        duration: 600,
        easing: 'cubicBezier(0.25, 0.1, 0.25, 1)'
      }, 0)
    } else {
      mainDot.setAttribute('r', this.initialDotR * 1.25)
    }
  }

  unPing() {
    setTimeout(() => {
      const randIndex = Math.floor(Math.random() * this.pingedIndexes().length)
      const mainDot = this.mainDots.item(randIndex)
      const rippleDot = this.rippleDots.item(randIndex)
      mainDot.classList.remove('pinged')
      rippleDot.classList.remove('pinged')

      anime({
        targets: mainDot[0],
        r: this.initialDotR,
        duration: 600,
        easing: 'cubicBezier(0.25, 0.1, 0.25, 1)'
      })

      window.requestAnimationFrame(tick => {
        this.unPing(tick)
      })
    }, randomInRange(3000, 10000))
  }
}
