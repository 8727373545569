import { Controller } from '@hotwired/stimulus'
import { apiURL, getURLParam } from '../utilities'

export default class extends Controller {
  static targets = [
    'emailablePrice', 'competitorPrice', 'skeleton', 'percentDiff'
  ]

  static values = { company: String, credits: String }

  connect() {
    this.getPrice()
  }

  getPrice() {
    const priceURL = new URL(`${apiURL()}/site/comparisons/${this.companyValue}`)

    priceURL.searchParams.append('credits', this.creditsValue)
    priceURL.searchParams.append('country', getURLParam('country'))

    fetch(priceURL).then(response => {
      response.json().then(data => {
        const ourPrice = data.our_price
        const competitorPrice = data.their_price

        this.emailablePriceTarget.textContent = ourPrice.formatted
        this.competitorPriceTarget.textContent = competitorPrice.formatted

        const percentDiff =
          Math.round(((competitorPrice.raw / ourPrice.raw) * 100) - 100)

        if (percentDiff <= 0) {
          this.percentDiffTarget.parentNode.remove()
        } else {
          this.percentDiffTarget.textContent = `${percentDiff}% more`
          this.percentDiffTarget.classList.remove('opacity-0')
        }

        this.skeletonTargets.forEach(target => {
          target.setAttribute('data-loading', false)
        })
      })
    })
  }
}
