import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  open(event) {
    const calendar = event.params.calendar
    this.#loadWidget().then(() => {
      const url = new URL(`https://calendly.com/emailable/${calendar}`)
      url.searchParams.append('text_color', '464678')
      url.searchParams.append('primary_color', '7E61FF')
      url.searchParams.append('hide_gdpr_banner', '1')
      Calendly.showPopupWidget(url.toString())
    })
  }

  #loadWidget() {
    return new Promise((resolve, _reject) => {
      if (typeof(Calendly) == 'object') return resolve()

      const script = document.createElement('script')
      script.setAttribute('src', 'https://calendly.com/assets/external/widget.js')
      script.addEventListener('load', () => resolve())
      document.body.insertAdjacentElement('beforeend', script)
    })
  }

}
