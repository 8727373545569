import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['slideCtr', 'slide', 'trigger']

  connect() {
    this.currentIndex = 0
  }

  slideToIndex(event) {
    const { index } = event.params
    const slideAmount = index / this.slideTargets.length * 100
    this.slideCtrTarget.style.transform = `translateX(-${slideAmount}%)`
    this.currentIndex = index

    this.triggerTargets.forEach(element => {
      element.setAttribute(
        'data-active',
        parseInt(element.getAttribute('data-index')) === index
      )
    })
  }
}
