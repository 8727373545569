import { shuffle } from './utilities.js'

export default class Shuffler {
  constructor(options) {
    this.data = options.data
    this.elements = options.elements
    this.interval = options.interval || false
    this.queueCallback = options.queueCallback || false
    this.shuffleData = options.shuffleData || false
    this.swapCallback = options.swapCallback || false
    this.prevIndex = options.elements.length - 1
  }

  queueItems() {
    if (this.shuffleData) {
      this.data = shuffle(JSON.parse(JSON.stringify(this.data)))
    }

    this.visibleItems = this.data.slice(0, this.elements.length)
    this.queue = this.data.slice(this.elements.length)

    // Create an object with an associated HTML Element for each entry in the data array
    this.elements.forEach((element, index) => {
      return this.visibleItems[index].element = element
    })

    this.queue.forEach((item) => {
      return item.element = null
    })

    if (!this.queueCallback) return

    return this.queueCallback(this.visibleItems, this.elements)
  }

  swapNextItem() {
    const targetIndex = this.prevIndex + 1 === this.visibleItems.length
      ? 0
      : this.prevIndex + 1

    // Get target item and element from visible list
    const targetItem = this.visibleItems[targetIndex]
    const targetElement = this.elements[targetIndex]

    // Nullify associated element of target item
    targetItem.element = null

    // Remove target item from visible list
    this.visibleItems.splice(targetIndex, 1)

    // Append target item to queue
    this.queue.push(targetItem)

    // Get the new item from the queue that will replace the target item
    const newItem = this.queue[0]
    newItem.element = targetElement

    // Remove new item from the queue and add it to the visible list
    this.queue.shift()
    this.visibleItems.push(newItem)

    this.prevIndex = targetIndex

    if (this.swapCallback) {
      if (this.interval) {
        return new Promise((resolve) => {
          this.swapCallback(newItem)
          resolve()
        }).then(
          setTimeout(() => {
            this.swapNextItem()
          }, this.interval)
        )
      } else {
        this.swapCallback(newItem)
      }
    } else {
      if (!this.interval) return

      setTimeout(() => {
        this.swapNextItem()
      }, this.interval)
    }
  }
}
