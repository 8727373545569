import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['days', 'hours', 'minutes', 'seconds']
  static values = { end: String, expired: Boolean }

  connect() {
    this.endDateTime = new Date(this.endValue).getTime()
    const second = 1000
    const minute = second * 60
    const hour = minute * 60
    const day = hour * 24

    if (this.getDistance() < 0) {
      this.expiredValue = true
    } else {
      window.countInterval = setInterval(() => {
        const distance = this.getDistance()
        const days = Math.floor(distance / day)
        const hours = Math.floor((distance % day) / hour)
        const minutes = Math.floor((distance % hour) / minute)
        const seconds = Math.floor((distance % minute) / second)
        this.daysTarget.textContent = days
        this.hoursTarget.textContent = hours
        this.minutesTarget.textContent = minutes
        this.secondsTarget.textContent = seconds

        if (distance < 0) {
          clearInterval(window.countInterval)
          this.expiredValue = true
        }
      }, 1000)
    }
  }

  getDistance() { return this.endDateTime - (new Date).getTime() }
}
