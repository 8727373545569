import { Controller } from '@hotwired/stimulus'
import { closestAncestor } from '../utilities'

export default class extends Controller {
  static targets = ['trigger', 'target', 'deactivator']
  static values = { active: Boolean }

  connect = () => {
    this.element[this.identifier] = this
  }

  activate = () => {
    this.activeValue = true
  }

  deactivate = (event) => {
    if (!this.activeValue) return

    const clickedTarget = closestAncestor(event.target, this.targetTarget)
    const clickedTrigger = closestAncestor(event.target, this.triggerTargets)
    const deactivateClicked = closestAncestor(
      event.target,
      this.deactivatorTargets
    )

    if (event && (clickedTarget || clickedTrigger) && !deactivateClicked) {
      return
    }

    this.activeValue = false
  }

  toggle = () => {
    if (this.hasTargetTargets) {
      this.targetTargets.forEach((target) => {
        target.setAttribute('data-active', !this.activeValue)
      })
    }

    this.activeValue = !this.activeValue
  }
}
